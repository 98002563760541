{
  "dirty": true,
  "raw": "v1.75.0-18-g204e0e208-dirty",
  "hash": "g204e0e208",
  "distance": 18,
  "tag": "v1.75.0",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.75.0",
    "major": 1,
    "minor": 75,
    "patch": 0,
    "prerelease": [],
    "build": [],
    "version": "1.75.0"
  },
  "suffix": "18-g204e0e208-dirty",
  "semverString": "1.75.0+18.g204e0e208.dirty"
}